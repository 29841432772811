@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  min-height: 100vh;
  font-family: 'Inter', sans-serif;
  color: #212432;
}

main {
  padding-top: 56px;
}

h1,
h2,
.font-muli {
  font-family: 'Inter', sans-serif;
}

h3 {
  font-size: 1rem;
}

h2 {
  font-size: 24px;
}

input[type='text'] {
  color: black
}

[type=radio] {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
}
/* IMAGE STYLES */
[type=radio] + img {
  cursor: pointer;
}

ul {
  margin-left: 1rem;
}

::-webkit-input-placeholder { /* Edge */
  color: red;
}

.react-date-picker__inputGroup__input {
  input {
    color: black;
    ::placeholder {
      color: black;
    }
  }
}

.react-date-picker__inputGroup__input:invalid {
  background: rgba(255, 255, 255, 1.0) !important;
  color: black !important;
}
.react-date-picker__inputGroup{
  display: flex;
  align-items: center;
  min-width: 0;
  flex-grow: 0 !important;
}
.react-date-picker__button{
  padding: 4px;
}
.react-date-picker__wrapper {
  height: 32px !important;
  color: black;
  border-radius: 5px;
  border: 1px solid #d9d9d9 !important;
  background-color: white;
  width:180px;
  input {
    &::placeholder {
      color: black;
      font-size: 18px;
    }
  }
}

.react-date-picker__calendar--open {
  z-index: 999;
}

.react-date-picker__inputGroup{

}

.react-date-picker__inputGroup__divider{
  flex-grow: 0 !important;
  max-width:8px;
}

.react-date-picker__button{
  padding: 0 3px !important;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #d9d9d9;
  opacity: 1; /* Firefox */
}

::marker {
  list-style-type: disc;
}


.max-width-calander {
  max-width: 175px;
}

@media screen and (min-width: 500px) {
  .node-lg {
    width: 60%;
  }
  .node-text-lg {
    max-width: 13rem;
  }
}

@media screen and (min-width: 1024px) {
  .node-lg {
    width: 65%;
  }
  .node-text-lg {
    max-width: 90%;
  }
}

@media screen and (min-width: 1300px) {
  .node-lg {
    width: 70%;
  }
  .node-margin {
    margin-left: -10rem;
  }
  .node-margin-right {
    margin-right: -10rem;
  }
}


.min-width-modal {
  min-width: 100%;

  @media only screen and (min-width: 600px) {
    min-width: 767px;
  }

}

.max-height-modal {
  @media only screen and (min-width: 1024px) {
    max-height: 700px;
    overflow-y: auto;
    overflow-x: hidden;
  }
}

.max-height-modal-mob {
  @media only screen and (max-width: 1023px) {
    max-height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;
  }
}

.list > li::before {
  display: inline-block;
  position: relative;
  content: '';
  width: 4px;
  height: 4px;
  margin-top: -5px;
  margin-right: 10px;
  border-radius: 50%;
  background-color: #fff;
}

/*
input[type=date]::-webkit-calendar-picker-indicator {
  display: none;
}*/
.page-container {
  padding-top: 58px;
}

.w-80 {
  width: 20rem;
}

.bin {
  color: #b64567;
}

.edit {
  color: #81ccd8;
}

.si {
  color: #34898a !important;
  --color-active: #34898a !important;
}

.public-DraftStyleDefault-ltr {
  font-size: 1rem;
}

.text-white {
  color: #fefbfb;
}

.text-black {
  color: #212432;
}

.text-gray {
  color: #D1D5DB;
}

.button-width {
  max-width: 400px;
}

.highInOrder {
  z-index: 999;
}

.bg-wa-green {
  background-color: #34898a;
  color: #fefbfb;
}

.bg-wa-bordeaux {
  background-color: #b64567;
  color: #fefbfb;
  width: 62px;
}

.wa-bg-orange {
  background-color: #f69f61;
  color: #fefbfb;
}


.bg-red-1000 {
  background-color: #b64567;
  color: #fefbfb;
}

.bg-green-1300 {
  background-color: #34898a;
  color: #fefbfb;
}

.bg-green-1000 {
  background-color: #2f5a5c;
  color: #fefbfb;
  cursor: pointer;
}

.bg-green-1200 {
  background-color: #2f5a5c;
  color: #fefbfb;
  cursor: pointer;
}

.bg-green-1100 {
  background-color: #81ccd8;
  color: #fefbfb;
  cursor: pointer;
}

.wa-connect {
  color: #81ccd8;
}

.wa-line {
  height: 2px;
  background-color: #34898a;
}

.wa-min-height {
  padding-bottom:10px;
}

.wa-dropdown-timeline-mb {
  right:-170px;
  top:-35px;
}

.wa-green-color {
  color: #34898a;
}

.wa-bordeaux-color {
  color: #b64567;
}

.wa-green-button-mb {
  font-size:9px;
}

@media (min-width: 640px) {
  .wa-green-button-mb {
    font-size: 1rem;
    width: 200px;
  }
}

.wa-input-mb {
  flex-grow:1;
}

.wa-red-color {
  color: #b64567;
}

.wa-blue-color {
  color: #81ccd8;
}

.wa-footer-bg {
  background-color: #f59d64;
  color: #fefbfb;
  border-radius: 2rem;
}

.wa-border-line {
  border-color: rgb(53, 137, 138);
}

.bg-wa-button-turquoise {
  background-color: #81ccd8;
  color: #fefbfb;
}

.bg-wa-button-light-green-hover {
  background-color: red;
  color: #fefbfb;
}

.bg-wa-button-light-green {
  background-color: #34898a;
  color: #fefbfb;
}

.bg-wa-button-green {
  background-color: #2f5a5c;
  color: #fefbfb;
  min-width: 62px;
}

.bg-wa-button-bordeaux {
  background-color: #b64567;
  color: #fefbfb;
}

.wa-border-bordeaux {
  border-color: #b64567;
}

.bg-wa-button-green-hover {
  background-color: #006d61;
}

.bg-wa-button-bordeaux {
  background-color: #b64567;
  color: #fefbfb;
}

.max-h-button {
  max-height: 33px;
}

.bg-wa-button-orange {
  background-color: #f49f67;
  color: #fefbfb;
}

.wa-main-bg {
  background-color: rgba(252, 243, 243, 0.9); //#fcf3f3;
}

.wa-content-bg {
  background-color: rgba(255, 255, 255, 0.9);
}

.wa-main-title-subpage {
  color: #212432;
}

.wa-titles-color {
  color: #34898a;
}

.wa-bg-bordeaux {
  background-color: #b64567;
  color: #fefbfb;
}

.wa-bg-green {
  background-color: #34898a;
  color: #fefbfb;
}

.wa-gray-light {
  color: #D1D5DB;
}

.wg-dark-green {
  color: #34898a;
}

.wg-dark-green-bg {
  background-color: #2f5a5c;
  color: #fefbfb;
}

.wa-light-green-bg {
  background-color: #34898a;
  color: #fefbfb;
}

.bg-green-600 {
  background-color: #34898a;
}

.bg-red-600 {
  background-color: #b64567;
}

.wa-inputfield-border {
  border-color: #fcf3f3;
}

.wa-inputfield-bg {
  background-color: #fcf3f3;
}

.RichEditor-root {
  border-color: #fefbfb;
  background-color: #fefbfb;
}

.wa-list-container-bg {
  background-color: #fcf3f3;
}

.wa-chevron-color {
  color: #b64567;
}

.wa-light-gray{
  background-color: #fcf3f3;
}


.wa-text-gray {
  color: rgb(113, 128, 150);
}

.hover\:bg-green-600:hover {
  background-color: #029d8b;
}

.hover\:bg-green-800:hover {
  background-color: #006d61;
}

.text-green-600 {
  color: #029d8b;
}

.hover\:border-green-600:hover {
  border-color: #029d8b;
}

.border-green-600 {
  border-color: #029d8b;
}

.main-logo {
  background-image: url('./../assets/images/WorkAppic.png');
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  position: relative;
  width: 65px;
  height: 65px;
}


.icon-cancelMarkedRound {
  background-image: url('./../assets/images/icons/cancel_icon.png');
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  position: relative;
  width: 32px;
  height: 32px;
}

.icon-checkedMarkedRound {
  background-image: url('./../assets/images/icons/checkcircle_icon.png');
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  position: relative;
  width: 32px;
  height: 32px;
}

.icon-checkedMarkedRoundOpen {
  background-image: url('./../assets/images/icons/checkcircle_icon.png');
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  position: relative;
  width: 32px;
  height: 32px;
}

.icon-tools {
  background-image: url('./../assets/images/icons/laptop-mobile-empty.png');
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  position: relative;
  width: 24px;
  height: 24px;
  margin-left: 8px
}

.icon-bear {
  background-image: url('./../assets/images/icons/beer-icon.png');
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  position: relative;
  width: 24px;
  height: 24px;
  margin-left: 8px
}

.icon-expert {
  background-image: url('./../assets/images/icons/expert-icon.png');
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  position: relative;
  width: 24px;
  height: 24px;
  margin-left: 8px
}

.icon-experience {
  background-image: url('./../assets/images/icons/experiences-icon-2.png');
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  position: relative;
  width: 24px;
  height: 24px;
  margin-left: 8px
}


input, textarea {
  border-radius: 5px;
  border: 1px solid hsl(0, 0%, 85%);
}

// **************************
// Login Page
// **************************

#login-image {
  background-image: url('./../assets/images/logo.jpeg');
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  position: relative;

  &:after {
    content: '';
    display: block;
    position: absolute;
    height: 100%;
    width: 100%;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
  }
}

#author-picture {
  background-image: url('./../assets/images/anon.png');
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

#google-logo {
  background-image: url('./../assets/images/google-logo.jpg');
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

#card {
  background-image: url('https://images.pexels.com/photos/212286/pexels-photo-212286.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260');
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

#main-header {
  height: 15vh;
  background-image: url('./../assets/images/header.jpg');
  background-size: 220%;
  background-position: 70% -70%;
  background-repeat: repeat;
  align-items: center;
  display: flex;
  background-color: white;
  &:after {
    content: '';
    display: block;
    height: 16vh;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.0);
    position: absolute;
    z-index: 1;
    top: 58px;
  }

  h1 {
    margin-top: 25px;
    z-index: 10;
  }
}

.react-date-picker__inputGroup {
  .react-date-picker__inputGroup__input--hasLeadingZero{
    min-width:11px!important;
  }
  .react-date-picker__inputGroup__month {
    min-width: 30px;
  }
  input {
    &:last-of-type {
      min-width: 40px;
    }
  }
}

@media (min-width: 768px) {
  #main-header {
    height: 20vh;
    background-image: url('./../assets/images/header.jpg');
    background-size: 125%;
    background-position: 50% 0;
    background-repeat: repeat;
    align-items: center;
    display: flex;
    background-color: white;

    &:after {
      content: '';
      display: block;
      height: 16vh;
      width: 100%;
      background-color: rgba(0, 0, 0, 0.0);
      position: absolute;
      z-index: 1;
      top: 58px;
    }

    h1 {
      margin-top: -15px;
      z-index: 10;
    }
  }
}

#dashboard-content {
  margin-top: -100px;
}

@media (min-width: 640px) {
  #quick-add-actie {
    left: 16.65%;
  }
}

#quick-add-actie {
  position: relative;
  top: 55px;
}

#quick-add-button {
  white-space: nowrap;
}

#profile-avatar,
#cardProfileAvatar {
  background-image: url('./../assets/images/anon.png');
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.recharts-wrapper {
  margin: 0;
  height: 100%;
}

.react-date-picker__calendar--open{
  z-index: 999 !important;
}

.recharts-surface {
  width: 100%;
  height: 100%;
  margin-top: 40px;
}

// #tooltip {
//   background-color: #48bb78;
// }

.algemene-slider .rangeslider .rangeslider__fill {
  background-color: #029d8b;
}

.algemene-slider.green .rangeslider .rangeslider__fill {
  background-color: #029d8b;
}

.algemene-slider.orange .rangeslider .rangeslider__fill {
  background-color: #ed8936;
}

.algemene-slider.red .rangeslider .rangeslider__fill {
  background-color: #e53e3e;
}

.navigatie-item {
  .is-active {
    position: relative;
    color: #029d8b;

    &:before {
      content: '';
      height: 2px;
      width: 100%;
      background-color: #029d8b;
      bottom: -3px;
      display: block;
      position: absolute;
      border-radius: 5px;
    }
  }
}

.navigatie-item {
  .is-active-submenu {
    color: #34898a;
  }
}

.icon-position {
  top: -3px;
}

#loader_container {
  transition: 0.3s;
}

#loader_loading {
  transition: 0.3s;
  -webkit-animation: rotation 1.75s infinite linear;
}

@-webkit-keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
  }
}

.draggable-list-item {
  list-style: none;

  .list-item-text {
    width: calc(100% - 110px);
  }
}

.react-tooltip-lite {
  background: #333;
  border-radius: 0.25rem;
  color: #fefbfb;
}

.react-tooltip-lite-arrow {
  border-color: #333;
}

.edit-array-item-title-input {
  width: calc(100% - 100px);
}

.actielijstItemClickHelper {
  width: calc(100% - 250px);
}

.selectLabelActielijstModule {
  width: calc(100% - 10rem);
}

.datum-input-actielijst-item {
  padding-bottom: 1px;
}

// PopUp Styles
@media (min-width: 768px) {
  #add-action-module {
    max-height: 60vh;
  }
}

// PopUp Styles
@media (max-width: 768px) {
  #add-action-module,
  .info-uitleg-popup {
    max-height: calc(100% - 70px);
    top: 30px;
    width: calc(100% - 10px);
    min-height: 300px; // Overrule crazy z-index by feedback tool
  }
  #item-module {
    height: calc(100%);
    top: 0px;
    width: calc(100% - 10px);
  }
  .todolist-text-container {
    padding-right: 0px !important;
    padding-top: 38px;
    display: inline-block;
  }
}

.actie-item-onvoltooid {
  .actie-icon-voltooid {
    display: none;
  }

  .actie-icon-onvoltooid {
    display: inline-block;
  }

  &:hover {
    .actie-icon-onvoltooid {
      display: none;
    }

    .actie-icon-voltooid {
      display: inline-block;
    }
  }
}

.transition-short {
  transition-timing-function: ease-in;
  transition: 0.3s;
}

.huidig-werkleven-popup {
  max-height: calc(100vh - 200px);
}

.Toastify__toast {
  border-radius: 0.25rem !important;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1),
  0 1px 2px 0 rgba(0, 0, 0, 0.06) !important;
}

.Toastify__toast--default {
  color: #4a5568 !important;
}

.Toastify__close-button.Toastify__close-button--default {
  margin-top: -5px;
}

.Toastify__progress-bar--default {
  background: #34898a !important;
}

.knipperlamp {
  animation: blinker 5s linear infinite;
  transition: 0.3s ease-in-out;
  position: relative;
  top: 5px;
}

@keyframes blinker {
  40% {
    color: #4a5568;
  }
  50% {
    color: #ed8936;
  }
  60% {
    color: #4a5568;
  }
}

@media (min-width: 768px) {
  .info-uitleg-popup {
    max-height: 500px;
    overflow-y: auto;
  }
}

.add-tooltip {
  &:before {
    content: '';
    display: block;
    position: absolute;
    width: 0;
    height: 0;
    border-color: transparent transparent #f7fafc;
    border-style: solid;
    border-width: 0 10px 10px;
    top: -10px;
    z-index: 10;
  }
}

.add-tooltip-right {
  margin-top: 53px;
  margin-right: -19px;

  &:before {
    right: 25px;
  }
}

.add-tooltip-middle {
  top: 2px;
  right: -82px;
  margin-top: 53px;

  &:before {
    right: calc(50% - 10px);
  }
}

.balk-image {
  background-image: url('./../assets/images/balk.png');
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  display: block;
  height: 100px;
  width: 100%;
}

.uitleg-menu {
  background-image: url('./../assets/images/uitleg_menu.png');
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  display: block;
  height: 450px;
  width: 100%;
}

.teddy-bear {
  background-image: url('./../assets/images/icons/beer-icon-5.png');
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  position: relative;
}

#stepper-afbeelding-1 {
  background-image: url('./../assets/images/dashboard-balk.png');
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  position: relative;
}

.todolist-text-container {
  padding-right: 300px;
}

.wa-text-mini {
  font-size: 0.8rem;
}

.mr-28 {
  margin-right: 7rem;
}

.small-input {
  .react-date-picker__wrapper{
    flex-grow: 0;
  }
}

@media (max-width: 768px) {
  #DashboardCardCijferChart {
    min-height: 200px;
  }
  .recharts-responsive-container {
    height: 210px !important;
  }
}

$white: #fefbfb;
$google-blue: #4285f4;
$button-active-blue: #1669F2;

.google-btn {
  width: 100%;
  height: 42px;
  background-color: $google-blue;
  border-radius: 2px;
  cursor: pointer;
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, .25);

  .google-icon-wrapper {
    position: absolute;
    margin-top: 1px;
    margin-left: 1px;
    width: 40px;
    height: 40px;
    border-radius: 2px;
    background-color: $white;
  }

  .google-icon {
    position: absolute;
    margin-top: 11px;
    margin-left: 11px;
    width: 18px;
    height: 18px;
  }

  .btn-text {
    float: left;
    margin: 11px 0px 0 60px;
    color: $white;
    font-size: 14px;
    letter-spacing: 0.2px;
    font-family: "Inter";
  }

  &:hover {
    box-shadow: 0 0 6px $google-blue;
  }

  &:active {
    background: $button-active-blue;
  }
}

.modal {
  transition: opacity 0.25s ease;
}

.modal-overlay {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 20;
}

.si {
  --color-default: #dee5f2;
  --color-active: #029d8b;
  --rotate-default: 180deg;
  --rotate-active: 40deg;
  --border-size-checkmark: 2px;
  --border-size-box: 1px;
  --input-size: 20px;
  --guter: 15px;
}

.si,
.si *,
.si *::before,
.si *::after {
  box-sizing: border-box;
}

.si {
  cursor: pointer;
  position: relative;
}

.si > input[type="checkbox"],
.si > input[type="radio"] {
  clip-path: polygon(0 0);
}

.si .si-label {
  display: inline-block;
  padding-left: var(--guter);
  color: rgb(74, 85, 104);
  vertical-align: text-top;
}

.si .si-label::before,
.si .si-label::after {
  transition: all 0.2s ease-in-out;
}

.si .si-label::before {
  content: '';
  display: block;
  width: var(--input-size);
  height: var(--input-size);
  border: var(--border-size-box) solid var(--color-default);
  position: absolute;
  top: -3px;
  left: 0;
  transform: rotate(0deg) scale(1);
}

.si .si-label:hover::before {
  border-color: var(--color-active);
}

.si.si-checkbox .si-label::before {
  border-radius: var(--border-size-checkmark);
}

.si.si-checkbox .si-label::after {
  content: '';
  display: block;
  width: 8px;
  height: 18px;
  border-width: 0 var(--border-size-checkmark) var(--border-size-checkmark) 0;
  border-style: solid;
  border-color: transparent var(--color-active) var(--color-active) transparent;
  position: absolute;
  top: -3px;
  left: 0;
  transform: rotate(var(--rotate-default)) scale(0);
}

.si.si-checkbox > input:checked + .si-label::after {
  left: 8px;
  transform: rotate(var(--rotate-active)) scale(1);
}

.si.si-checkbox > input:checked + .si-label::before {
  transform: rotate(var(--rotate-active)) scale(0);
}

.si.si-radio .si-label::before,
.si.si-radio .si-label::after {
  border-radius: 100%;
  top: -2px;
}

.si.si-radio .si-label::after {
  content: '';
  display: block;
  position: absolute;
  width: 12px;
  height: 0;
  top: 100%;
  left: 4px;
}

.si.si-radio > input:checked + .si-label::after {
  background-color: #fff;
  height: 12px;
  top: 2px;
}

.si.si-radio > input:checked + .si-label::before {
  background-color: var(--color-active);
}

.si.si-switcher .si-label {
  padding-left: 45px;
}

.si.si-switcher .si-label::before {
  content: '';
  width: 36px;
  height: 20px;
  border-radius: 20px;
  top: -2px;
}

.si.si-switcher .si-label::after {
  content: '';
  border-radius: 4px;
  width: 6px;
  height: 12px;
  background-color: var(--color-default);
  position: absolute;
  top: 2px;
  left: 7px;
}

.si.si-switcher > input:checked + .si-label::before {
  background-color: var(--color-active);
  border-color: var(--color-active);
}

.si.si-switcher > input:checked + .si-label::after {
  background-color: #fff;
  left: 24px;
}

.si [data-onchecked="show"],
.si > input:checked ~ [data-onchecked="hide"] {
  display: none;
}

.si [data-onchecked="hide"],
.si > input:checked ~ [data-onchecked="show"] {
  display: inline-block;
}

.si > input:checked + .si-label.line-across {
  text-decoration: line-through;
}
